import React, { useState } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';

import IconGraphic from '../atoms/IconGraphic';
import IconCalendar from '../atoms/IconCalendar';
import IconDocument from '../atoms/IconDocument';
import IconCoinBag from '../atoms/IconCoinBag';
import IconBank from '../atoms/IconBank';
import IconScreen from '../atoms/IconScreen';
import IconBell from '../atoms/IconBell';
import IconPaperCalendar from '../atoms/IconPaperCalendar';
import IconPerson from '../atoms/IconPerson';
import IconBill from '../atoms/IconBill';
import IconCreditCard from '../atoms/IconCreditCard';
import IconPaper from '../atoms/IconPaper';
import ImageTaxes from '../atoms/ImageTaxes';
import ImageEmitter from '../atoms/ImageEmitter';
import ImageNotifications from '../atoms/ImageNotifications';
import ImageManagement from '../atoms/ImageManagement';
import FuncionalityButton from '../molecules/FuncionalityButton';
import FuncionalityItem from '../molecules/FuncionalityItem';
import Customer from '../../static/files/Customer.json';
import VittudeImg from '../../static/images/cliente-conube-1.png';
import VittudeLogo from '../../static/images/logo-vittude.png';
import JqlImg from '../../static/images/cliente-conube-2.png';
import JqlLogo from '../../static/images/logo-jql.png';
import UmRecadoImg from '../../static/images/cliente-conube-3.png';
import UmRecadoLogo from '../../static/images/logo-1recado.png';
import { getImage } from '../functions/useStaticImages';

const Functionalities = ({
  color,
  hasCustomerComment,
  taNaModa,
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    title: {
      display: 'grid',
      gridTemplateColumns: '30% auto',
      marginBottom: '60px',

      '& .MuiTypography-subtitle1': {
        letterSpacing: '0.3em',
        textTransform: 'uppercase',
        color: theme.palette[color].main,
        marginBottom: '10px',
      },

      '@media screen and (max-width: 1200px)': {
        gridTemplateColumns: '100%',
      },
    },
    buttons: {
      display: 'grid',
      gridTemplateColumns: '25% 25% 25% 25%',

      '@media screen and (max-width: 1200px)': {
        display: 'none',
      },
    },
    desktopList: {
      '@media screen and (max-width: 1200px)': {
        display: 'none',
      },
    },
    mobileList: {
      '@media screen and (min-width: 1200px)': {
        display: 'none',
      },
    },
  }));
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);

  const images = getImage();
  const VittudeImg = images.allImageSharp.nodes.find(n => n.fixed.originalName === 'cliente-conube-1.png').fixed;
  const JqlImg = images.allImageSharp.nodes.find(n => n.fixed.originalName === 'cliente-conube-2.png').fixed;
  const UmRecadoImg = images.allImageSharp.nodes.find(n => n.fixed.originalName === 'cliente-conube-3.png').fixed;

  const funcionalitiesList = [
    {
      mainIcon: <IconGraphic />,
      title: 'Gestão financeira',
      description: 'Controle as finanças do seu negócio por meio dos nossos painéis integrados e relatórios contábeis atualizados.',
      icon1: <IconBank />,
      icon2: <IconScreen />,
      property1: 'Integração com bancos digitais',
      property2: 'Gráficos e relatórios interativos',
      image: <ImageManagement id="management_" color={color} />,
      imageMobile: <ImageManagement id="mmanagement_" color={color} />,
      customer: {
        ...Customer.comments[0],
        src: VittudeImg,
        logo: VittudeLogo,
      },
    }, {
      mainIcon: <IconCalendar />,
      title: 'Notificações',
      description: 'A gente te lembra de tudo! Mantenha sua empresa sempre em dia com nosso método de notificações de atividades.',
      icon1: <IconBell />,
      icon2: <IconPaperCalendar />,
      property1: 'Lembretes de obrigações fiscais',
      property2: 'Calendário de atividades sempre atualizado',
      image: <ImageNotifications id="notifications_" color={color} />,
      imageMobile: <ImageNotifications id="mnotifications_" color={color} />,
      customer: {
        ...Customer.comments[1],
        src: JqlImg,
        logo: JqlLogo,
      },
    }, {
      mainIcon: <IconDocument />,
      title: 'Emissor de NFS-e',
      description: 'Emita suas notas fiscais eletrônicas pela própria plataforma, e de maneira integrada ao seu fluxo financeiro.',
      icon1: <IconPerson />,
      icon2: <IconBill />,
      property1: 'Salve contatos para emissão frequente',
      property2: 'Gere boletos para cobrar seus clientes',
      image: <ImageEmitter id="emitter_" color={color} />,
      imageMobile: <ImageEmitter id="memitter_" color={color} />,
      customer: {
        ...Customer.comments[2],
        src: UmRecadoImg,
        logo: UmRecadoLogo,
      },
    }, {
      mainIcon: <IconCoinBag />,
      title: 'Impostos',
      description: 'Conte com um painel completo para gerenciar os impostos da sua empresa e pagá-los digitalmente pela plataforma.',
      icon1: <IconCreditCard />,
      icon2: <IconPaper />,
      property1: 'Pagamento digital de impostos',
      property2: 'Informações detalhadas',
      image: <ImageTaxes id="taxes_" color={color} />,
      imageMobile: <ImageTaxes id="mtaxes_" color={color} />,
      customer: {
        ...Customer.comments[0],
        src: VittudeImg,
        logo: VittudeLogo,
      },
    }
  ];

  return (
    <Box className={classes.root}>
      <Box className={classes.title}>
        <Box>
          <Typography variant="subtitle1">Autonomia</Typography>
          <Typography variant="h4" component="h2">
            Tecnologia a serviço <br /> da sua empresa
          </Typography>
        </Box>

        <Box className={classes.buttons}>
          <FuncionalityButton
            icon={<IconGraphic />}
            text="Gestão financeira"
            selected={tabIndex === 0}
            handleClick={() => setTabIndex(0)}
            color={color}
          />
          <FuncionalityButton
            icon={<IconCalendar />}
            text="Notificações"
            selected={tabIndex === 1}
            handleClick={() => setTabIndex(1)}
            color={color}
          />
          <FuncionalityButton
            icon={<IconDocument />}
            text="Emissor de NFS-e"
            selected={tabIndex === 2}
            handleClick={() => setTabIndex(2)}
            color={color}
          />
          <FuncionalityButton
            icon={<IconCoinBag />}
            text="Impostos"
            selected={tabIndex === 3}
            handleClick={() => setTabIndex(3)}
            color={color}
          />
        </Box>
      </Box>

      <SwipeableViews index={tabIndex} onChangeIndex={setTabIndex} className={classes.desktopList}>
        {funcionalitiesList.map((funcionality) => (
          <FuncionalityItem
            funcionality={funcionality}
            color={color}
            hasCustomerComment={hasCustomerComment}
            taNaModa={taNaModa}
          />
        ))}
      </SwipeableViews>

      <Box className={classes.mobileList}>
        {funcionalitiesList.map((funcionality) => (
          <FuncionalityItem
            funcionality={funcionality}
            color={color}
            hasCustomerComment={hasCustomerComment}
            taNaModa={taNaModa}
          />
        ))}
      </Box>
    </Box>
  );
};

Functionalities.propTypes = {
  color: PropTypes.string,
  hasCustomerComment: PropTypes.bool,
};

Functionalities.defaultProps = {
  color: 'primary',
  hasCustomerComment: false,
};

export default Functionalities;
